.hero-section__wrapper {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.hero-section__content {
  width: 100%;
  background: linear-gradient(90deg, rgba(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  color: white;
  padding-block: 5rem !important;
  font-family: "Montserrat";
}

.hero-section__content p {
  font-size: 1.2rem;
  font-weight: 700;
}

#mantra-logo {
  width: 45%;
  height: 100%;
  object-fit: contain;
}

.btn-secondary {
  color: var(--color-primary) !important;
  border-radius: 50px;
  text-decoration: none !important;
  background: white;
  padding: 0.8rem 1.8rem;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 1rem;
}

#register-btn {
  margin-top: 1rem !important;
}

#how-to-reg {
  width: 100%;
  padding: 1rem 5rem 5rem;
}

.qa-card {
  border-radius: 18px;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  background-position: top right;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  padding: 1rem;
  background: #d9d9d9;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 1.2rem;
}

.qa-question {
  font-weight: 700;
}


@media screen and (max-width: 768px) {
  .hero-section__content p {
    font-size: 1rem;
  }

  #how-to-reg {
    width: 100%;
    padding: 0;
  }

  #mantra-logo {
    width: 65%;
  }
}

@media screen and (max-width: 600px) {
  .hero-section__content p {
    text-align: center;
  }

  #mantra-logo {
    width: 100%;
  }
  #register-btn {
    margin-top: 1rem !important;
    margin-inline: auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}
