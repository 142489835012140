.register-wrapper {
    background: #C7DAF2;
}

.form-control {
    width: 100%;
    height: 3rem;
    border-radius: 8px;
    border: none;
    padding: 0 1rem;
    margin-bottom: 1rem !important;
    font-weight: 600 !important;
    color: black !important;
}

::placeholder {
    color: #D7D7D7 !important;
    font-weight: 600 !important;
}

form {
    font-family: 'Montserrat' !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.btn-primary {
    background: var(--color-primary) !important;
    color: white !important;
    font-weight: 700 !important;
    width: max-content !important;
    border-radius: 32px !important;
    font-size: 1.2rem !important;
    padding: 0.5rem 1.5rem !important;
}