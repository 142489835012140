.info-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  gap: 4rem;
}

.info-wrapper h2 {
  font-family: "Montserrat";
  color: var(--color-primary);
  font-weight: 800;
  text-align: center;
  font-size: 2.8rem;
}

.card-wrapper {
  border-radius: 32px;
  overflow: hidden;
  background-position: top right;
  background-size: cover;
  background-repeat: no-repeat;
  /* width: 100%; */
  height: 100%;
}

.info-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.8rem;
}

.card-content {
  min-height: 18rem;
  height: 100%;
  padding: 1.5rem;
  background: linear-gradient(90deg, rgba(0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
  font-family: "Montserrat";
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.card-content h3 {
  font-size: 2rem;
  font-weight: 800;
  max-width: 70%;
  margin-bottom: 0.5rem;
  line-height: 2.2rem;
}

.card-content p {
  max-width: 60%;
}

@media screen and (max-width: 768px) {
    .info-wrapper h2 {
        font-size: 2rem;
      } 
}

@media screen and (max-width: 600px) {
  
    .info-wrapper {
        gap: 2rem;
      }
.info-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, minmax(0,1fr));
    gap: 0.8rem;
  }
}
