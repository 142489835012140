@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
:root {
    --color-primary: #0D3956;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

.yt-video {
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
    border-radius: 32px;
}