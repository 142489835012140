.syllabus-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#syllabus-banner {
  margin: 0;
  padding: 0rem;
  height: 15rem;
  width: 100%;
  margin-bottom: 10rem;
}

.accordion {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
}

.details-syllabus {
  display: flex;
  width: 80%;
  /* justify-content: center; */
  margin: auto;
}

.stack-circle {
  width: 7rem;
  height: 7rem;
  border-radius: 100% !important;
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
}

.stack-circle img {
  width: 90%;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.stack-details {
  margin:  2rem 0rem 1rem 2rem;
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  /* width:90% !important; */
  /* height: 3rem; */

}


.accordion-details{
   width: 100%;
}

.syllabus-web-banner{
  display:block;
}

.syllabus-mobile-banner{
  display:none;
}

.stack-circle-mobile {
  display:none;
}
img{
  /* max-width: 100%; */
}
@media (max-width:480px) {

  .syllabus-web-banner{
    display:none;
  }

  .syllabus-mobile-banner{
    display:block;
  }
  .accordion-details {
    margin: 1rem;
  }
  .accordion {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
    margin-top: 6rem;
}

  #syllabus-banner {
    margin: 0;
    padding: 0rem;
    height: 15rem;
    width: 100%;
   
  }


  /* .details-syllabus{
  display:flex;
  width:55.5rem;
} */
.stack-circle-mobile {
  display: block;
  width: 4rem;
  height: 4rem;
  border-radius: 5rem;
  margin: auto;
  margin-bottom: 1rem;
}
.stack-circle{
 display:none;

}
/* .stack-details{
  margin: 2rem 1rem 0rem 3rem;
  width:45rem;
  height:3rem;

} */
.stack-details {
  margin: 3rem 0rem 0rem 0rem;
  /* width: 90% !important; */
  /* height: 3rem; */
  /* width: 28rem !important; */
}

}