.test-heading {
  display: flex;
  width: 100%;
  margin-top: 20px;
  justify-content: center;
  font-weight: 600;
  letter-spacing: 0.25rem;
  font-size: 2rem;
  font-family: "Lucida Sans";
}
.container-main {
  display: flex;
  flex-direction: row;
  margin: 4rem;
  justify-content: space-between;
}

.text-container {
  font-size: 1.75rem;
}

.text-container > p {
  font-size: 2rem;
  font-family: "Lucida Sans";
  font-weight: 600;
  letter-spacing: 0.25rem;
}

.code-blue {
  font-size: 3rem;
  color: #4ca3ff;
  letter-spacing: 0.25rem;
  font-family: Franklin Gothic;
  display: block;
}

.image-container {
  width: 60%;
  height: 70vh;
  object-fit: cover;
  object-position: top -130px right -42px;
}

.footer-dark {
  display: flex;
  border-bottom: 1px solid #e4ebf3;
  background-color: #f5f7fa;
  width: 100%;
  height: 4rem;
  position: sticky;
  bottom: 0;
  z-index: 50;
  align-items: center;
  justify-content: center;
}
.footer-copyright-text {
  font-size: 1rem;
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: rgb(100, 100, 100);
  text-align: center;
}
.header-nav {
  border-bottom: 1px solid rgb(90, 90, 90);
}
.header-nav > img {
  margin-top: 1%;
  margin-left: 5%;
  margin-bottom: 1%;
  height: 5vh;
  width: auto;
}

.code-red {
  font-size: 3rem;
  color: #ff4c58;
  letter-spacing: 0.25rem;
  font-family: Franklin Gothic;
  display: block;
}

.code-blue-sm {
  font-size: 2rem;
  color: #4ca3ff;
  letter-spacing: 0.25rem;
  font-family: Franklin Gothic;
  display: block;
}
.tuned-in{
  font-weight: 600;
}
@media (max-width: 480px) {
  .container-main {
    display: flex;
    flex-direction: column-reverse;
    margin: 2rem;
  }
  .code-blue {
    font-size: 2rem;
  }
  .text-container > p {
    font-size: 1.25rem;
  }
  .image-container {
    width: 100%;
    height: auto;
    object-position: 0 0;
  }
  .test-heading {
    font-size: 1rem;
  }
  .code-blue-sm {
    font-size: 1.25rem;
    color: #4ca3ff;
    letter-spacing: 0.25rem;
    font-family: Franklin Gothic;
    display: block;
  }
  .code-red {
    font-size: 2rem;
    color: #ff4c58;
    letter-spacing: 0.25rem;
    font-family: Franklin Gothic;
    display: block;
  }
}
