.all-cards{
    display:grid;
    grid-template-columns: auto auto;
    grid-gap: 0rem 5rem;
    align-items: center;
    justify-content: center;
}
#resource-banner {
    margin: 0;
    padding: 0rem;
    height: 15rem;
    width: 100%;
    margin-bottom: 10rem;
    
  }
  .resource-web-banner{
    display:block;
  }
  
  .resource-mobile-banner{
    display:none;
  }
  

.ResourseCard{
    display:flex;
    width:35rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    height: 10rem;
    cursor: pointer;
}

@media (max-width:480px){
    .resource-web-banner{
        display:none;
      }
      
      .resource-mobile-banner{
        display:block;
      }
    .all-cards{
        display:grid;
        grid-template-columns: auto;
        grid-gap: 0rem 5rem;
        align-items: center;
        justify-content: center;
    }
    
    .ResourseCard{
        display:flex;
        width:20rem;
        margin-top: 3rem;
        margin-bottom: 3rem;
        height: 14rem;
        flex-direction: column;
        
    }
}