aside {
    width:10rem;
    height:100vh;
    /* margin: 0rem 0rem -31rem 0rem; */
    z-index: 40 !important;
}
.background1{
    background-color: #16375c;
}
.sidebar{
    width:15rem;
   
}
.white{
    color: white;
    margin: 0rem 0rem 0rem 1rem;

}
.underline:hover{

    background-color: rgb(86, 81, 81);
    border-left: 8px solid blue;
    
}
.underline{
    border-bottom: 1px solid white;
}

.logout{
    margin: 0rem 0rem 0rem 1rem;
    position: relative;
    top: -2rem;
}
.nav{
    height: 28rem;
    top: 1rem;
    position: relative;
}
.img1{
    width:12rem;
    height:8rem;
    margin: 0 auto;
    /* background: red; */
}
.mobile{
    display:none;
}
.web{
    display: block;
}
@media (max-width:480px) {
    .mobile{
        display: block;
        position: relative;
        color: rgb(247, 251, 255);
       top:0.5rem;
        font-size: 2rem;
    }
    .web{
        display: none;
    }
  .nav{
    justify-content: space-evenly;
  }
    aside {
       
        height: 11vh;
        width: 100%;
        margin: 0rem 0rem -1rem 0rem;
    }

    .sidebar{
        width:100%
    }
    .white{
        color: white;
        margin: 0rem 0rem 0rem 1rem;
    
    }
    .underline:hover{
        background-color: rgb(86, 81, 81);
        border-left: 8px solid blue;
        
    }
    
    .img1{
        width:14.5rem;
        height:4.5rem;
        display: none;
    }
    .nav{
        height: 2.5rem;
        width: 100%;
        top: 0.5rem;
        position: relative;
    }

}