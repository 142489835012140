.container {
    width: 80%;
    font-size: 15px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#scholarship-banner {
    /* margin: 0; */
    padding: 0rem;
    height: 15rem;
    width: 100%;
    margin-bottom: 5rem;
    
  }
  .scholarship-web-banner{
    display:block;
  }
  
  .scholarship-mobile-banner{
    display:none;
  }
  
.tabs {
    width: 80%;
    margin: auto;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tab-content {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.table-title {
    width: 100%;
    display: flex;
    /* font-size: 20px; */
    margin-top: 12px;
    text-align: center;

}



.details-table td,
.details-table th {
    border: 1px solid #000;
    padding: 8px;
}

.details-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: rgba(179, 204, 250, 0.15);
    text-align: center;

    /* color: white; */
    /* font-size: 15px; */

}

.details-table tr {
    background-color: rgba(179, 204, 250, 0.15);
    text-align: center;

}

.body-table {
    width: 90%;
    border-collapse: collapse;
}

.body-table td,
.body-table th {
    border: .5px solid #000;
    padding: 8px;
    text-align: center;

}

.body-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #16375c;
    color: white;
    text-align: center;

    /* font-size: 15px; */

}

.body-table tr {
    background-color: rgba(179, 204, 250, 0.15);
}

.center-content {
    margin: 25px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    word-wrap: break-word;
}

@media (max-width:480px) {
    .scholarship-web-banner{
        display:none;
      }
      
      .scholarship-mobile-banner{
        display:block;
      }
    .container {
        width: 100%;
        font-size: 10px;

    }

    .tab-content {
        width: 100%;

    }

    .tab-content .details-table {
        width: 100%;
        overflow: scroll !important;

    }
}