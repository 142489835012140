.sidebar {
    flex: 3;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    gap:1rem;
  }
  
  .sidebarItem {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .sidebarTitle {
    margin: 10px;
    padding: 5px;
    width: 80%;
    border-top: solid 1px #a7a4a4;
    border-bottom: solid 1px #a7a4a4;
    text-align: center;
    font-family: "Varela Round", sans-serif;
    font-size: 15px;
    line-height: 19px;
    color: #16375C;
    font-weight: 600;
  }

  .sidebarTitle a{
    text-decoration: none;
  }
  
  .sidebarItem > p {
    padding: 30px;
    font-size: 20px;
    font-weight:400;
  }
  
  .sidebarList {
    list-style-type: none;
    margin-bottom: 30px;
  }
  
  .sidebarListItem {
    display: inline-block;
    width: 50%;
    margin-top: 15px;
    cursor: pointer;
  }
  
  .sidebarSocial {
    margin-top: 15px;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .sidebarIcon {
    font-size: 16px;
    margin-left: 10px;
  }