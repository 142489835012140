.TestimonialsCard{
    display:flex;
    width:30rem;
    flex-direction: column;
    margin-top: 3rem;
    margin-bottom: 3rem;
    /* height: 23rem; */
    cursor: pointer;
    text-align: center;
}

 .TestimonialsCard > h6{
    text-align: left;
    padding:  .5rem 1rem 1rem 1rem;

} 
iframe{
    width:30rem;
    /* height:18rem; */
}
@media (max-width:480px){
    .TestimonialsCard{
        display:flex;
        width:20rem;
        flex-direction: column;
        margin-top: 3rem;
        margin-bottom: 3rem;
        /* height: 18rem; */
        cursor: pointer;
    }
    .TestimonialsCard > h1{
        display: block;
        margin: auto;
        font-size: 1.5rem;
        text-align: center;
    }
    iframe{
        width: 20rem;
        /* height: 14rem; */
    }
}