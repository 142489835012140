.singlePost {
    flex: 9;
    padding: 0rem 0rem 0rem 5rem;
  }
  
  .singlePostWrapper {
    padding: 20px;
    padding-right: 0;
  }
  
  .singlePostImg {
    width: 100%;
    height: 65vh;
    border-radius: 5px;
    object-fit: cover;
  }
  
  .singlePostTitle {
    text-align: left;
    color: #16375c;
    margin: 10px 10px 16px;
    font-size: 2.2rem;
    font-weight: 600;
    padding: 0.5rem 0rem;
    font-family: "Lora", sans-serif;
  }
  
  .singlePostEdit {
    float: right;
    font-size: 16px;
  }
  
  .singlePostIcon {
    margin-left: 10px;
    cursor: pointer;
  }
  
  .singlePostIcon:first-child {
    color: teal;
  }
  .singlePostIcon:last-child {
    color: tomato;
  }
  
  .singlePostInfo {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #16375c;
    font-family: "Varela Round", Arial, Helvetica, sans-serif;
  }
  
  .singlePostAuthor{
    margin-left: 5px;
  }
  
  .singlePostDesc {
    width: 100%;
    height: auto;
    padding-top: 2rem;
    background-color: #fff;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    color: #666;
    font-size: 18px;
    line-height: 25px;
  }
  
  .singlePostDesc::first-letter {
    margin-left: 20px;
    font-size: 30px;
    font-weight: 600;
  }