.flex-col-center{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: flex-start;
    background-color: #f5f5f5;
    padding: 2rem 12%;
    gap: 2rem;
}

.flex-col-center > h1 {
    width: 100%;
    text-align: center;
}

.term {
    width: 100%;
    text-align: justify;
}

.list{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    height: auto;
}

.term:hover{
    background-color: transparent;
    border: none;
}

.padding{
    padding: 2rem;
}