.highlight-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.padding-default {
    padding: 4rem 12%;
}



@media screen and (max-width: 768px) {
    .highlight-wrapper {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .padding-default {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 600px) {
    .highlight-wrapper {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .padding-default {
        padding: 2rem 2rem;
    }
}
 
.swiper {
    height: 100%;
    border-radius: 40px;
    position: relative;
}


.swiper-slide {
    border-radius: 40px;
    min-height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.highlight-text, .registration-count div {
    text-align: center;
    font-size: 1.5rem;
    font-family: 'Montserrat';
    font-weight: 500;
    color: #19375C;
    margin: auto;
}

.highlight-text h2, .registration-count h2 {
    font-weight: 800;
    font-size: 3rem;
    line-height: 3.5rem;
}

#swiper-pagination {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    transform: translateX(-50%);
    z-index: 1;
    width: 100% !important;
}

.swiper-pagination-bullet-active {
    background: #fff !important;
    opacity: 1;
}

.swiper-pagination-bullet {
    background: #fff !important;
    opacity: 0.8;
}

.registration-count {
    background-color: #C7DAF2;
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    margin-top: 2rem;
}

.registration-count div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    gap: 0.5rem;
}


.reg-img {
    width: 40%;
}
.registration-count img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


@media screen and (max-width: 768px) {
    .reg-img {
        display: none !important;
    }
}
