.container{
    width: 100%;
    margin-top: 6rem;
}
#downloads-banner {
    margin: 0;
    padding: 0rem;
    height: 15rem;
    width: 100%;
    margin-bottom: 5rem;
  }
  .downloads-web-banner{
    display:block;
  }
  
  .downloads-mobile-banner{
    display:none;
  }
  .download-btn{
    font-size: large;
    padding-left: 2rem;
    color: black;
  }
  @media (max-width:1024px){
    #downloads-banner {
      margin: 0;
      padding: 0rem;
      height: 8rem;
      width: 100%;
      
    }
    .downloads-web-banner{
      display: block;
    }
  }
  @media (max-width:480px){
    #downloads-banner {
        margin: 0;
        padding: 0rem;
        height: 15rem;
        width: 100%;
        
      }
      .downloads-web-banner{
        display:none;
      }
      
      .downloads-mobile-banner{
        display:block;
      }
      
  }