
.main-body {
    padding: 2rem;
}
.card {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06);
}
.flex{
    display: flex;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem;
}

.gutters-sm {
    /* margin-right: -8px; */
    /* margin-left: -8px; */
}

.gutters-sm>.col, .gutters-sm>[class*=col-] {
    padding-right: 8px;
    padding-left: 8px;
}
.mb-3, .my-3 {
    margin-bottom: 1rem!important;
}

.bg-gray-300 {
    background-color: #e2e8f0;
}
.h-100 {
    height: 100%!important;
}
.shadow-none {
    box-shadow: none!important;
}
.profile{
    /* margin: -12rem 0rem 0rem 0rem; */
}
.move{
    /* position: relative; */
    /* top:-14rem; */
}
@media (max-width:480px){
    .move{
        position: relative;
        top:0rem;
    }
    .profile{
        margin:0rem 0rem 0rem 0rem;
    }
    .main-body {
        padding: 1rem;
    }
    .flex{
        display: block;
    }
}