.h1 {
    /* margin: 0rem 0rem 2rem 20rem; */
    padding: 2rem;
    text-align: center;
    /* position: relative; */
    /* top: -8rem; */
}

.flex {
    display: flex;
}

.countdown {
    background-color: #456D93;
    width: 100%;
    padding: 2rem;
    /* position: relative; */
    /* top: -12rem; */
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.days {
    width: 50%;
    font-size: 3rem;
    display: flex;
    align-items: center;
    text-align: center;
    /* background-color: red; */
    justify-content: space-around;
    margin: auto;
}

.font {
    font-size: 1rem;
    padding-left: .5rem;
    color: #D3E5FF;
}

.loader {
    text-align: center;
    height: 20rem;
    background: #D3E5FF;
    width: 100%;
    padding: 0 2rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card2 {
    width: 18rem;
    /* margin: 0rem 0rem 0rem 5rem; */
}
.bar{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    transform: translateY(1rem);

}
.indexedStep {
    color: white;
    width: 35px;
    height: 35px;
    background-color: rgba(211, 211, 211, 0.8);
    border-radius: 50%;
}

.indexedStep.accomplished {
    background-color: #456d93;
    border: 3px solid #fff;

}
.label{
    font-size: 1rem;
    padding-top: 1.3rem;
    color: #456D93;
}
.label > a{
    
    text-decoration: none;
    color: #456D93;
}
.label > a:hover{
    color: #456D93;
}
.cards {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    
    margin-bottom: 2rem;

}

.card-text {
    margin: 0rem 0rem 0rem 0rem;
}

@media (max-width:480px) {

    .flex {
        display: block;
    }

    .label {
    font-size: .7rem;

    }

    .indexedStep {
        width: 20px;
        height: 20px;
        border: 1px solid #fff;

    }

    .card2 {
        width: 18rem;
        margin-bottom: 1rem;
        /* position: relative; */
        /* top: 5rem; */
    }

    .cards {
        display: flex;
    width: 100%;
    /* margin: 1rem; */
    top: 0rem;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
    }

}

@media (min-width:480px) {
    .cards {
        gap: 1rem;
        flex-wrap: wrap;
    }
}